<template>
    <routerView />
</template>
  
<script>
export default {
    created() {
        console.log("Admin Layout");
    }
};
</script>
  